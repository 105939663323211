import React, { useState, useRef } from 'react'
import '../NeonHorizon.css'

const NeonHorizonWeb = () => {
    const [isFullScreen, setIsFullScreen] = useState(false); // Track full-screen state
    const iframeRef = useRef(null); // Reference to the iframe
  
    // Function to handle full-screen toggle
    const toggleFullScreen = () => {
      if (!isFullScreen) {
        // Enter full-screen mode
        if (iframeRef.current) {
          iframeRef.current.requestFullscreen()
            .then(() => setIsFullScreen(true))
            .catch(err => console.error("Failed to enter full-screen mode:", err));
        }
      } else {
        // Exit full-screen mode
        document.exitFullscreen()
          .then(() => setIsFullScreen(false))
          .catch(err => console.error("Failed to exit full-screen mode:", err));
      }
    };
  
    return (
      <div className='NeonHorizonContainer'>
        {/* Iframe for the game */}
        <iframe
          ref={iframeRef}
          src="/arrow-dash-web/arrow_dash.html"
          title="Desktop Game"
          className='NeonHorizonWindow'
        />
    
    <div className='GameTitle'>ARROW DASH</div>

        {/* Full-Screen Toggle Button*/}
        <button onClick={toggleFullScreen} className='FullScreenButton'>
          {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
        </button>
        
      </div>
    )
}

export default NeonHorizonWeb